<template>
  <div>
    <md-list-item class="md-layout">

      <div class="md-layout md-alignment-center md-xsmall-size-85">

        <div class="md-layout-item md-small-hide">
          <md-avatar class="md-large">
            <img v-if="object.image_path" :src="getFile(object.image_path)"
                 alt="image of the product">
            <img v-else src="@/assets/logo-arene.png" alt="image of the product">
          </md-avatar>
        </div>

        <div class="md-layout-item md-xsmall-size-100 md-list-item-text">
          <span>{{ object.name }}</span>
        </div>

        <div class="md-layout-item md-xsmall-size-100">
          <span>{{ object.stock }}</span>
          <span class="md-xsmall-10"> {{ object.stock > 1 ? 'pièces' : 'pièce' }}</span>
        </div>

        <div class="md-layout-item md-xsmall-size-100">
          <span>{{ object.minimum_stock }}</span>
          <span class="md-xsmall-10"> {{ object.minimum_stock > 1 ? 'pièces' : 'pièce' }}</span>
        </div>
      </div>

      <div class="md-layout md-layout-item md-size-10 md-small-20 md-xsmall-size-15">
        <div class="btn-edit">
          <md-button @click="onEdit" class="md-icon-button md-dense md-raised md-accent btn-green">
            <md-icon>mode</md-icon>
          </md-button>
        </div>
        <md-button @click="active = true" class="md-icon-button md-dense md-raised md-accent">
          <md-icon>delete</md-icon>
        </md-button>
      </div>

      <md-dialog-confirm
        :md-active.sync="active"
        md-title="Supprimer produit"
        md-content="Vous voulez vraiment supprimer cet produit?"
        md-confirm-text="Confirmer"
        md-cancel-text="Annuler"
        @md-confirm="onConfirm"/>

    </md-list-item>
    <md-divider class="md-full"/>
  </div>
</template>

<script>

import ProductService from '@/components/product/ProductService';
import staticFileMixin from '@/mixins/static-file-mixin';
import itemMixin from '@/mixins/item-mixin';

export default {
  name: 'StockItem',
  data: () => ({
    service: ProductService,
  }),
  mixins: [staticFileMixin, itemMixin],
};

</script>
